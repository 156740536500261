<template>
  <div>
    <div 
      :style="{background: `#ccc url(${require('@/assets/img/bg-home.jpg')}) center/cover no-repeat`}"
      style="min-height: 70vh"
      class="d-f ai-c"
    >
      <div
        class="container pv-2 ta-c ta-l@s"
      >
        <img
          src="@/assets/img/logo-sm.svg"
          style="height: 7rem"
          alt=""
          class="d-n d-ib@s mb-5"
        >

        <h1
          class="fw-l c-w mt-0 mb-5 fz-48@s lh-12 maxw-s"
        >
          Trusted integrated cloud solutions <br>for your business
        </h1>

        <a
          href="#more"
          class="d-ib bd-w fz-24 fw-l c-g15 bg-w bdR-r ph-3 td-n mt-2@s o-8 o:h-10"
          style="padding-top:0.5rem;padding-bottom:0.7rem"
        >Learn more</a>
      </div>
    </div>

    <div
      id="more"
      class="container"
    >
      <div class="d-f pv-4 nml-5 nmt-5 fd-c fd-r@s">
        <div
          class="fg-1 pl-5 pt-5"
          style="flex-basis: 0.01%"
        >
          <h3 class="fz-22 fz-30@s fw-l">
            Problem
          </h3>
          <div class="fz-18 fw-l">
            A new generation of cloud-based technologies is revolutionizing business computing while demanding new skills and approaches. New solutions create the potential to leap ahead of competitors stuck with large sunk costs in outdated software and infrastructure.
          </div>
        </div>

        <div
          class="fg-1 pl-5 pt-5"
          style="flex-basis: 0.01%"
        >
          <h3 class="fz-22 fz-30@s fw-l">
            Our solution
          </h3>
          <div class="fz-18 fw-l">
            Tactalx was founded to help New Zealand businesses rebase their technology from a money pit and management headache to a center of excellence and innovation. By embracing innovation and best practices we want to radically alter the way computing and communications are delivered.
          </div>
        </div>

        <div
          class="fg-1 pl-5 pt-5"
          style="flex-basis: 0.01%"
        >
          <h3 class="fz-22 fz-30@s fw-l">
            Next steps
          </h3>
          <div class="fz-18 fw-l">
            Over the next few months, we will be launching new products and services to help you do business beautifully by integrating and customizing cloud solutions to drive your business forward.
          </div>
        </div>
      </div>

      <div class="ta-c mt-5">
        <router-link
          to="/contact"
          class="d-b d-ib@s tt-u td-n bg-g15 bg:h-p c-w ph-5 pv-1 bdR-r fz-20 fw-l"
        >
          Get in touch
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    metaInfo: {
      title: 'Tactalx: Trusted integrated cloud solutions for your business',
      // override the parent template and just use the above title only
      titleTemplate: null
    }
}
</script>