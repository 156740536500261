<template>
  <div>
    <div class="container">
      <h1>
        Contact
      </h1>

      <div class="fz-18">
        You can contact us via the form below or directly at <a
          href="mailto:contact@tactalx.com"
          class="c-p"
        >contact@tactalx.com</a>
      </div>

      <form
        style="max-width: 60rem"
        class="fz-22 mt-4"
        @submit.prevent="submit"
      >
        <div>
          <input
            v-model="name"
            type="text"
            class="w-1/1 bd-n bdb-g80 fw-l p-1"
            placeholder="Your name"
            required
          >
        </div>

        <div class="mt-4">
          <input
            v-model="subject"
            type="text"
            class="w-1/1 bd-n bdb-g80 fw-l p-1"
            placeholder="Subject"
            required
          >
        </div>
      
        <div class="mt-4">
          <textarea
            v-model="msg"
            rows="10"
            class="w-1/1 bd-n bdb-g80 fw-l p-1"
            placeholder="Message"
          />
        </div>

        <div class="mt-4">
          <button
            type="submit"
            class="d-ib w-1/1 w-a@s tt-u bg-g15 bg:h-p c-w ph-5 pv-1 bdR-r bd-n fz-20 fw-l"
            style="cursor: pointer"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: 'Contact',
  },
  data: function() {
    return {
        name: '',
        subject: '',
        msg: ''
    };
  },
  methods:{
    submit(){
      window.open(`mailto:contact@tactalx.com?subject=${this.subject}&body=Name: ${this.name}%0D%0AMessage: ${this.msg}`)
    }
  }
}
</script>