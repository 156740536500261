<template>
  <div
    id="app"
    style="min-height: 100vh"
    class="d-f fd-c"
  >
    <nav class="d-f ai-c ph-4vw pv-1 pv-2@s">
      <router-link to="/">
        <img
          src="@/assets/img/logo.svg"
          height="40"
          class="d-b"
        >
      </router-link>

      <router-link
        v-if="$route.path.indexOf('contact') === -1"
        to="/contact"
        class="ml-a fw-b tt-u td-n c-g15 c:h-p"
      >
        Contact
      </router-link>
      <router-link
        v-else
        to="/"
        class="ml-a fw-b tt-u td-n c-g15 c:h-p"
      >
        Home
      </router-link>
    </nav>

    <router-view />

    <div class="mt-a">
      <footer
        class="bg-g30"
        style="margin-top: 10rem"
      >
        <div class="container ta-c c-g80 pv-3">
          © 2020 Tactalx Ltd. All Rights Reserved.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | Tactalx'
    }
}
</script>