import '@/assets/scss/general/normalize.scss'
import '@/assets/scss/general/reset.scss'
import '@/assets/scss/basic/page.scss'
// import '@/assets/scss/basic/image.scss'
import '@/assets/scss/basic/headings.scss'
// import '@/assets/scss/basic/anchor.scss'
// "@/assets/css/basic/bridge.scss",

import '@/assets/scss/objects/container.scss'
// import '@/assets/scss/objects/g.scss'
// "@/assets/scss/objects/fe.scss",
// import '@/assets/scss/objects/i.scss'

// import "swiper/dist/css/swiper.min.css"; // critical to display swiper properly before init it

// "@/assets/scss/components/In.scss",

// "@/assets/scss/libs/overrides.scss",

// import '@/assets/scss/staging.scss'
import '@/assets/scss/trumps.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  render: h => h(App),
}).$mount('#app')
